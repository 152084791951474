import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Informazioni e servizi | Offerta pneumatici TreadSmart
			</title>
			<meta name={"description"} content={"Precisione in ogni curva"} />
			<meta property={"og:title"} content={"Informazioni e servizi | Offerta pneumatici TreadSmart"} />
			<meta property={"og:description"} content={"Precisione in ogni curva"} />
			<meta property={"og:image"} content={"https://veloxianbit.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://veloxianbit.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://veloxianbit.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://veloxianbit.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://veloxianbit.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://veloxianbit.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://veloxianbit.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Text margin="0px 0px 90px 0px" text-align="center" font="normal 600 42px/1.2 --fontFamily-sans" md-margin="0px 0px 60px 0px">
			Informazioni e servizi
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="56px 34px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 130px 0px 130px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					I nostri servizi spiegati
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					Noi di TreadSmart Tires ci assicuriamo che ogni veicolo che trattiamo sia equipaggiato per affrontare qualsiasi sfida stradale con la massima sicurezza ed efficienza. I nostri servizi pneumatici completi sono progettati per prolungare la durata dei vostri pneumatici e migliorare la vostra esperienza di guida.
					</Text>
				</Box>
				<Image src="https://veloxianbit.com/img/3.jpg" display="block" width="100%" md-order="-1" />
				<Image src="https://veloxianbit.com/img/4.jpg" display="block" width="100%" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 30px 0px 20px"
					align-items="flex-start"
					md-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
					La nostra competenza
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
					La nostra struttura è dotata degli strumenti e delle tecnologie più recenti per fornire servizi di pneumatici di alto livello. Dai veicoli di tutti i giorni alle auto ad alte prestazioni, il nostro approccio è personalizzato per soddisfare le esigenze e gli standard specifici del vostro veicolo.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h2"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Soluzioni complete per i pneumatici
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
					Installazione di pneumatici
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Montaggio di precisione: Ci assicuriamo che i vostri pneumatici siano montati correttamente utilizzando attrezzature all'avanguardia.
					<br/><br/>
Equilibratura avanzata: Il nostro processo di equilibratura riduce le vibrazioni e migliora il comfort di guida.

					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Allineamento delle ruote
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Controlli accurati dell'allineamento: Utilizziamo la tecnologia di allineamento laser per garantire che le ruote siano perfettamente allineate.
					<br/><br/>
Regolazioni: I nostri tecnici effettuano regolazioni precise per migliorare la maneggevolezza e ridurre l'usura dei pneumatici.

					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Manutenzione dei pneumatici
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Controlli della pressione: Controlli regolari per garantire che i vostri pneumatici siano gonfiati alla pressione ideale per la sicurezza e le prestazioni.
					<br/><br/>
Servizi di rotazione: Ruotiamo i vostri pneumatici per favorire un'usura uniforme e prolungarne la durata.

					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Servizi pneumatici stagionali
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Pneumatici invernali: Pneumatici speciali per i mesi più freddi per una migliore trazione e sicurezza.
					<br/><br/>
Pneumatici estivi: Pneumatici ad alte prestazioni che migliorano l'aderenza e la stabilità nelle stagioni calde.

					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});